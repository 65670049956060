import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { AiOutlinePlayCircle, AiOutlineUser } from 'react-icons/ai';
import { BsClock } from 'react-icons/bs';
import { secondsToTimeText } from '../../../../helpers/time';
import { useParams } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import {
  updateVideoProgress as updateVideoProgressService,
  startPreview as startPreviewService,
  finishPreview as finishPreviewService,
} from '../../../../services/content/index';
import {
  VideoContentContainer,
  VideoOrLessonTitle,
  VideoTabHeaderContainer,
  VideoTabsContent,
  VideoTabsHeaders,
} from './style';
import GlobalContext from '../../../../GlobalContext';
import PlayerSambaVideos from '../../../../components/PlayerSambaVideos';
import { getContent as getContentService } from '../../../../services/content';
import Lesson from '../../../../models/lesson';
import usePostHog from '../../../../hooks/usePosthog';
import CourseTabsMobile from '../../../Course/Components/CourseContent/Components/CourseTabsMobile';
import { LessonOverviewContainer } from '../../../Course/Components/CourseContent/Components/LessonOverview/styles';
import LessonAnnotations from './LessonAnnotations';
import { VideoExtraMaterial } from './VideoExtraMaterial';
import { FaCrosshairs } from 'react-icons/fa';
import setTargetGroup from '../../../../helpers/converters/convert-target-group';
export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface VideoTabHeader {
  id: Tabs;
  name: string;
  shouldShow: boolean;
}

interface VideoTabHeaderProps {
  header: VideoTabHeader;
  selected: boolean;
}

interface VideoContentParams {
  contentId: string;
}

type Tabs = 'overview' | 'extra-material' | 'annotations';

const SingleVideo: React.FC = () => {
  const { contentId } = useParams<VideoContentParams>();
  const [content, setContent] = useState({} as Lesson);
  const posthog = usePostHog();
  const [shouldShowPlayer, setShouldShowPlayer] = useState(false);
  const { isMobile } = useContext(GlobalContext);
  const [hasFinishedVideo, setFinishedVideo] = useState<boolean>(false);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState('overview' as Tabs);
  const [progress, setProgress] = useState<number>(0);
  const contentRef = useRef<Lesson | null>(null);

  useEffect(() => {
    (async () => {
      const localContent = await getContentService(contentId);

      if (localContent && localContent.id) {
        setContent(localContent);
        contentRef.current = localContent;
        setFinishedVideo(localContent.alreadyFinished);
      }
    })();
  }, [contentId]);

  const getControlsToEnable = useCallback(() => {
    return [
      'play',
      'pause',
      'quality',
      'fullscreen',
      'time',
      'volume',
      'backward',
      'forward',
      'seekbar',
    ];
  }, [hasFinishedVideo]);

  useEffect(() => {
    if (content && Object.keys(content).length) {
      posthog?.capture(`Video ${content.title}`, {
        lessonName: content.title,
        lessonId: content.id,
      });
    }
  }, [content, posthog]);

  const onStartVideo = async () => {
    if (contentRef.current && !contentRef.current.alreadyStarted) {
      await startPreviewService(contentId);
      return;
    }
  };

  const courseTabHeaders = [
    { id: 'overview', name: 'Sobre', shouldShow: true },
    {
      id: 'annotations',
      name: 'Anotações',
      shouldShow: true,
    },
  ] as VideoTabHeader[];

  const courseTabHeadersToShow = useMemo(() => {
    return courseTabHeaders.filter(tab => tab.shouldShow);
  }, []);

  const ContentOverview: React.FC = () => {
    return (
      <LessonOverviewContainer>
        <p>{HTMLReactParser(content.description)}</p>
        <h3>
          <b>Materiais de Apoio</b>
        </h3>
        <div>
          <VideoExtraMaterial content={content} />
        </div>
      </LessonOverviewContainer>
    );
  };

  const changeTab = (headerId: Tabs) => {
    setSelectedTab(headerId);
  };

  const tabToBeRendered = useMemo((): JSX.Element => {
    switch (selectedTab) {
      case 'overview':
        return <ContentOverview />;
      case 'extra-material':
        return <VideoExtraMaterial content={content} />;
      case 'annotations':
        return <LessonAnnotations />;
    }
  }, [selectedTab, ContentOverview, content]);

  const CourseTabHeader: React.FC<VideoTabHeaderProps> = ({
    header,
    selected,
  }) => {
    return (
      <VideoTabHeaderContainer
        className={selected ? 'selected' : ''}
        onClick={() => changeTab(header.id)}
      >
        {header.name}
      </VideoTabHeaderContainer>
    );
  };

  const onFinishVideo = async () => {
    if (
      contentRef.current &&
      !contentRef.current.alreadyFinished &&
      !hasFinishedVideo
    ) {
      await finishPreviewService(contentRef.current.id);
      setFinishedVideo(true);
      return;
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateVideoProgressService(content.id, time);

      return;
    },
    [content.id],
  );

  const resumeTime = useMemo(() => {
    if (!content) {
      return false;
    }
    if (content.content_view) {
      setLastWatchedTime(content.content_view);
      return content.content_view;
    } else if (!!content.content_view) {
      const { content_view } = content;
      setLastWatchedTime(content_view);
      return content_view ? content_view : false;
    }

    return false;
  }, [content.id]);

  const updateWatchTime = useCallback(
    (player: PlayerEventListener) => {
      const { event, eventParam } = player;

      if (event === 'onProgress') {
        setProgress(eventParam);
      }
      return null;
    },
    [lastWatchedTime],
  );

  const getEventListeners = useCallback((player: PlayerEventListener) => {
    if (player.event === 'onProgress') {
      updateWatchTime(player);
      return;
    }

    if (player.event === 'onStart') {
      onStartVideo();
      setLastWatchedTime(resumeTime ? resumeTime : 0);
      return;
    }

    if (player.event === 'onFinish') {
      onFinishVideo();
      return;
    }
  }, []);

  useEffect(() => {
    setShouldShowPlayer(false);

    process.nextTick(() => {
      let localContentUrl = content.reference;
      const validUrlStart =
        'https://fast.player.liquidplatform.com/pApiv2/embed/';
      const isValidUrl =
        localContentUrl && localContentUrl.startsWith(validUrlStart);

      if (isValidUrl) {
        setShouldShowPlayer(true);
      }
    });
  }, [content.reference]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [progress]);

  return (
    <VideoContentContainer>
      {!content || !content.id ? (
        <div
          style={{
            height: '70vh',
            margin: '0 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="spinner"></div>
        </div>
      ) : (
        <>
          <div className="upper-content">
            <div className="category">
              {content.categories && content.categories[0].title}
            </div>

            <h2 className="title">{content?.title}</h2>
          </div>

          <div className="about-content">
            <span className="author">
              <AiOutlineUser size={20} color="#7324F2" />
              <span>Autor: {content?.authors && content.authors[0].name }</span>
            </span>

            <span className="duration">
              <BsClock size={20} color="#7324F2" />
              <span>Duração: {secondsToTimeText(content?.duration)}</span>
            </span>
            {!!content.target_group && content.target_group.length > 0  ? (
            <span>
              <FaCrosshairs
                size={20}
                color="var(--default-purple)" />
              <span> Público Alvo: {setTargetGroup(content?.target_group)} </span>
            </span>) : null
            }
          </div>

          <div className="video-content">
            <div className="video-container">
              <div className="player-container">
                {shouldShowPlayer ? (
                  <PlayerSambaVideos
                    contentReference={content.reference}
                    controlsToEnable={getControlsToEnable()}
                    resume={+resumeTime}
                    getEventListeners={getEventListeners}
                  />
                ) : (
                  <div className="player-icon-container">
                    <div className="player-icon">
                      <AiOutlinePlayCircle color="#FFF" size={200} />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <VideoOrLessonTitle>{content.title}</VideoOrLessonTitle>
            {!isMobile ? (
              <>
                <VideoTabsHeaders>
                  {courseTabHeadersToShow && courseTabHeadersToShow.length ? (
                    courseTabHeadersToShow.map(header => (
                      <CourseTabHeader
                        key={header.id}
                        header={header}
                        selected={selectedTab === header.id}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </VideoTabsHeaders>
                <VideoTabsContent>{tabToBeRendered}</VideoTabsContent>
              </>
            ) : (
              <CourseTabsMobile
                tabHeader={courseTabHeaders}
                contentsWithTabId={[
                  { id: 'overview', content: <ContentOverview /> },
                  {
                    id: 'extra-material',
                    content: <VideoExtraMaterial content={content} />,
                  },
                  { id: 'annotations', content: <LessonAnnotations /> },
                ]}
              />
            )}
          </div>
        </>
      )}
    </VideoContentContainer>
  );
};

export default SingleVideo;
