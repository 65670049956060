import React, { useCallback, useEffect, useState } from 'react';
import CourseCard from '../../components/CourseCard';
import CourseCardSkeleton from '../../components/CourseCardSkeleton';
import CourseCardWithoutImage from '../../components/CourseCardWithoutImage';
import CourseCardWithoutImageSkeleton from '../../components/CourseCardWithoutImageSkeleton';
import usePostHog from '../../hooks/usePosthog';
import Course from '../../models/course';
import {
  getCourses as getCoursesService,
  getOngoingCourses,
} from '../../services/course';
import MyCoursesContext from './context';
import {
  Favorites,
  FavoritesCardsContainer,
  MyCoursesContainer,
  MyCoursesSubTitle,
  MyCoursesTitle,
  Ongoing,
  OngoingCardsContainer,
} from './style';

const MyCourses: React.FC = () => {
  const [ongoingCourses, setOngoingCourses] = useState([] as Course[]);
  const [favoriteCourses, setFavoriteCourses] = useState([] as Course[]);
  const [shouldShowOngoingSkeleton, setShouldShowOngoingSkeleton] =
    useState(true);
  const [shouldShowFavoriteSkeleton, setShouldShowFavoriteSkeleton] =
    useState(true);

  const posthog = usePostHog();

  const refreshFavoriteCourses = useCallback(async () => {
    setFavoriteCourses([]);

    const favoriteFilters = {
      book_mark: true,
    };

    const localFavoriteCourses = await getCoursesService(favoriteFilters);
    if (localFavoriteCourses && localFavoriteCourses.length) {
      setFavoriteCourses([]);
      setFavoriteCourses(localFavoriteCourses);
    } else {
      setShouldShowFavoriteSkeleton(false);
    }
  }, []);

  const refreshOngoingCourses = useCallback(async () => {
    const ongoingFilters = {};

    const localOngoingCourses = await getOngoingCourses(ongoingFilters);

    if (localOngoingCourses && localOngoingCourses.length) {
      setOngoingCourses([]);
      setOngoingCourses(localOngoingCourses);
    } else {
      setShouldShowOngoingSkeleton(false);
    }
  }, []);

  useEffect(() => {
    posthog?.capture('Pagina Meus Cursos');
  }, [posthog]);

  useEffect(() => {
    (async () => {
      await refreshOngoingCourses();

      await refreshFavoriteCourses();
    })();
  }, [refreshFavoriteCourses, refreshOngoingCourses]);

  return (
    <MyCoursesContext.Provider
      value={{ refreshFavoriteCourses, refreshOngoingCourses }}
    >
      <MyCoursesContainer>
        <div
          className="content"
          style={{
            background: 'white',
            borderRadius: '5px',
            paddingTop: '5px',
            paddingBottom: '15px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <MyCoursesTitle>Meus Cursos</MyCoursesTitle>

          <Ongoing>
            <MyCoursesSubTitle>Em andamento...</MyCoursesSubTitle>

            <OngoingCardsContainer>
              {ongoingCourses && ongoingCourses.length ? (
                ongoingCourses.map((course, index) => (
                  <CourseCardWithoutImage key={index} course={course} />
                ))
              ) : shouldShowOngoingSkeleton ? (
                Array(4)
                  .fill('')
                  .map((item, index) => (
                    <CourseCardWithoutImageSkeleton key={index} />
                  ))
              ) : (
                <h4>Não foram encontrados cursos em andamento.</h4>
              )}
            </OngoingCardsContainer>
          </Ongoing>

          <Favorites>
            <MyCoursesSubTitle>Cursos favoritados</MyCoursesSubTitle>

            <FavoritesCardsContainer>
              {favoriteCourses && favoriteCourses.length ? (
                favoriteCourses.map((course, index) => (
                  <CourseCard
                    key={index}
                    onAddToList={() => {
                      refreshFavoriteCourses();
                    }}
                    course={course}
                  />
                ))
              ) : shouldShowFavoriteSkeleton ? (
                Array(4)
                  .fill('')
                  .map((item, index) => <CourseCardSkeleton key={index} />)
              ) : (
                <h4>Não foram encontrados cursos favoritados.</h4>
              )}
            </FavoritesCardsContainer>
          </Favorites>
        </div>
      </MyCoursesContainer>
    </MyCoursesContext.Provider>
  );
};

export default MyCourses;
