import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CourseCard from '../../../../components/CourseCard';
import { RecommendedCoursesContainer } from './style';
import CourseCardSkeleton from '../../../../components/CourseCardSkeleton';
import { toast } from 'react-toastify';
import Course from '../../../../models/course';
import { getCourses as getCoursesService } from '../../../../services/course';
import GlobalContext from '../../../../GlobalContext';
import Slider from 'react-slick';
import { RecommendedCoursesSliderSettings } from '../../../../config/slider';
import usePostHog from '../../../../hooks/usePosthog';

const RecommendedCourses: React.FC = () => {
  const history = useHistory();
  const posthog = usePostHog();
  const { isMobile } = useContext(GlobalContext);
  const [recommendedCourses, setRecommendedCourses] = useState([] as Course[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);
  const [shouldShowViewMoreButton, setShouldShowViewMoreButton] =
    useState(false);

  const goToAllCourses = () => {
    posthog?.capture('"Ver todos os cursos"');
    history.push('/courses');
  };

  const handleCoursesRender = useCallback(() => {
    const insideRender =
      recommendedCourses && recommendedCourses.length ? (
        recommendedCourses
          .slice(0, 4)
          .map(course => <CourseCard key={course.id} course={course} />)
      ) : shouldShowSkeleton ? (
        Array(4)
          .fill('')
          .map((i, index) => <CourseCardSkeleton key={index} />)
      ) : (
        <h4>Não foram encontrados cursos recomendados.</h4>
      );

    if (isMobile && recommendedCourses.length > 1) {
      return (
        <Slider {...RecommendedCoursesSliderSettings}>{insideRender}</Slider>
      );
    }
    return <div className="recommended-courses-cards">{insideRender}</div>;
  }, [isMobile, recommendedCourses, shouldShowSkeleton]);

  useEffect(() => {
    (async () => {
      try {
        const localCourses = await getCoursesService({
          limit: 4,
          is_active: true,
        });

        if (localCourses && localCourses.length) {
          setRecommendedCourses(localCourses);
        } else {
          setShouldShowSkeleton(false);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos recomendados.');
        setShouldShowSkeleton(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setShouldShowViewMoreButton(
        !!(
          await getCoursesService({
            limit: 1,
            offset: 4,
            is_active: true,
          })
        ).length,
      );
    })();
  }, []);

  return (
    <RecommendedCoursesContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2 className="recommended-courses-title">Últimos lançamentos</h2>
        {isMobile && shouldShowViewMoreButton && (
          <button className="show-all mobile" onClick={goToAllCourses}>
            Ver todos
          </button>
        )}
      </div>

      {handleCoursesRender()}

      {!isMobile && shouldShowViewMoreButton && (
        <div className="button-show-all-courses-container">
          <button onClick={goToAllCourses}>Ver todos</button>
        </div>
      )}
    </RecommendedCoursesContainer>
  );
};

export default RecommendedCourses;
