import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AiOutlinePlayCircle } from 'react-icons/ai';
import { useParams } from 'react-router';
import PlayerSambaVideos from '../../../../components/PlayerSambaVideos';
import CourseContext from '../../Context';
import LessonAnnotations from './Components/LessonAnnotations';
import LessonExtraMaterial from './Components/LessonExtraMaterial';
import LessonOverview from './Components/LessonOverview';
import {
  CourseBanner,
  CourseContentContainer,
  CourseOrLessonTitle,
  CourseTabHeaderContainer,
  CourseTabsAndRating,
  CourseTabsContent,
  CourseTabsHeaders,
  PlayerContainer,
  PlayerIcon,
  PlayerIconContainer,
  StartCourseButton,
  StartCourseContainer,
} from './styles';
import { CourseExtendedWindow } from '../../index';
import Lesson from '../../../../models/lesson';
import GlobalContext from '../../../../GlobalContext';
import CourseTabsMobile from './Components/CourseTabsMobile';
import { FiPlay } from 'react-icons/fi';
import usePostHog from '../../../../hooks/usePosthog';

declare let window: CourseExtendedWindow;

interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface CourseTabHeader {
  id: Tabs;
  name: string;
  shouldShow: boolean;
}

interface CourseTabHeaderProps {
  header: CourseTabHeader;
  selected: boolean;
}

interface CourseParams {
  courseId: string;
  moduleId: string;
  lessonId: string;
}

type Tabs = 'overview' | 'extra-material' | 'annotations';

const CourseContent: React.FC = () => {
  const { courseId, moduleId, lessonId } = useParams<CourseParams>();
  const {
    selectedLesson,
    course,
    finishLesson,
    startLesson,
    goToNextLesson,
    updateContentProgress,
    startCourse,
  } = useContext(CourseContext);
  const { isMobile } = useContext(GlobalContext);
  const [selectedTab, setSelectedTab] = useState('overview' as Tabs);
  const [contentReference, setContentReference] = useState('');
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);

  const actualSelectedLesson = useMemo(() => {
    return selectedLesson as Lesson;
  }, [selectedLesson]);

  const posthog = usePostHog();

  const getControlsToEnable = () => {
    const controls = [
      'play',
      'pause',
      'quality',
      'fullscreen',
      'time',
      'volume',
      'backward',
    ];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      controls.push('seekbar');
    }

    if (selectedLesson && selectedLesson.alreadyFinished) {
      controls.push('forward', 'seekbar');
    }

    return controls;
  };

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 0.95;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const checkIsCompleted = async (player: PlayerEventListener) => {
    const { event, eventParam, duration } = player;
    if (event !== 'onProgress' || !eventParam) {
      return false;
    }

    if (hasReachedCompletionTime(duration, eventParam)) {
      await finishLesson();
    }
  };
  const getEventListeners = useCallback(
    async (player: PlayerEventListener) => {
      if (
        window.actualCourseId === courseId &&
        window.actualModuleId === moduleId &&
        window.actualLessonId === lessonId &&
        (window.location.href.endsWith(
          `/course/${courseId}/module/${moduleId}/lesson/${lessonId}`,
        ) ||
          window.location.href.endsWith(`/course/${courseId}`))
      ) {
        if (player.event === 'onProgress') {
          if (
            (actualSelectedLesson.id &&
              !actualSelectedLesson.alreadyFinished) ||
            (!actualSelectedLesson.id && !course.previewData.finished)
          ) {
            updateWatchTime(player);
            await checkIsCompleted(player);
          }

          return;
        }

        if (actualSelectedLesson && actualSelectedLesson.id) {
          switch (player.event) {
            case 'onStart':
              setLastWatchedTime(resumeTime ? resumeTime : 0);
              await startLesson();
              break;

            case 'onFinish':
              await finishLesson();
              goToNextLesson();
              break;
          }
        } else if (course.referenceUrl) {
          switch (player.event) {
            case 'onStart':
              startLesson(true);
              break;
            case 'onFinish':
              finishLesson(undefined, true);
              break;
          }
        }
      }
    },
    [
      actualSelectedLesson.courseId,
      actualSelectedLesson.moduleId,
      actualSelectedLesson.id,
    ],
  );

  const changeTab = (headerId: Tabs) => {
    setSelectedTab(headerId);
  };

  const CourseTabHeader: React.FC<CourseTabHeaderProps> = ({
    header,
    selected,
  }) => {
    return (
      <CourseTabHeaderContainer
        className={selected ? 'selected' : ''}
        onClick={() => changeTab(header.id)}
      >
        {header.name}
      </CourseTabHeaderContainer>
    );
  };

  const tabToBeRendered = useMemo((): JSX.Element => {
    switch (selectedTab) {
      case 'overview':
        return <LessonOverview />;
      case 'annotations':
        return <LessonAnnotations />;
    }
  }, [selectedTab]);

  const isWatchingLesson = useMemo(() => {
    return !!(
      selectedLesson &&
      (selectedLesson.type === 'AUDIO' || selectedLesson.type === 'VIDEO')
    );
  }, [selectedLesson]);

  const courseTabHeaders = [
    { 
      id: 'overview', 
      name: 'Sobre', 
      shouldShow: true 
    },
    {
      id: 'extra-material',
      name: 'Material de Apoio',
      shouldShow: false,
    },
    {
      id: 'annotations',
      name: 'Anotações',
      shouldShow: isWatchingLesson,
    },
  ] as CourseTabHeader[];

  const updateWatchTime = useCallback(
    (player: PlayerEventListener) => {
      const { event, eventParam } = player;

      if (event === 'onProgress') {
        setProgress(eventParam);
      }
      return null;
    },
    [lastWatchedTime],
  );

  const courseTabHeadersToShow = useMemo(() => {
    return courseTabHeaders.filter(tab => tab.shouldShow);
  }, [course, selectedLesson]);

  const resumeTime = useMemo(() => {
    if (!selectedLesson && !course.previewData) {
      return false;
    }
    if (selectedLesson as Lesson) {
      setLastWatchedTime((selectedLesson as Lesson).content_view!);
      return (selectedLesson as Lesson).content_view;
    } else if (!!course.previewData?.contentView) {
      const { contentView } = course.previewData;
      setLastWatchedTime(contentView);
      return contentView ? contentView : false;
    }

    return false;
  }, [selectedLesson]);

  useEffect(() => {
    setContentReference('');

    window.setTimeout(() => {
      setSelectedTab('overview');
      if (actualSelectedLesson.reference) {
        setContentReference(actualSelectedLesson.reference);
      } else {
        setContentReference(course.referenceUrl);
      }
    }, 100);
  }, [selectedLesson, course.referenceUrl]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress, !(selectedLesson as Lesson).id);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress, !(selectedLesson as Lesson).id);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null, !(selectedLesson as Lesson).id);
    }
  }, [progress]);

  useEffect(() => {
    if (
      (selectedLesson && selectedLesson.type === 'AUDIO') ||
      selectedLesson.type === 'VIDEO'
    ) {
      if (course && Object.keys(course).length) {
        posthog?.capture(
          `Curso ${course.title}${
            selectedLesson && Object.keys(selectedLesson).length
              ? `, Aula ${selectedLesson.title}`
              : ''
          }${selectedTab && selectedTab.length ? `, Aba ${selectedTab}` : ''}`,
          {
            courseName: course.title,
            courseId: course.id,
            lessonName: selectedLesson.title,
            lessonId: (selectedLesson as Lesson).id,
            tab: (() => {
              switch (selectedTab) {
                case 'overview':
                  return 'Sobre';
                case 'extra-material':
                  return 'Material de Apoio';
                case 'annotations':
                  return 'Anotações';
                default:
                  return '';
              }
            })(),
          },
        );
      }
    }
  }, [course, selectedLesson, selectedTab, posthog]);

  const goToBannerLink = (bannerLink: string) => {
    posthog?.capture(
      `Clique no banner do Curso: ${course.title}, Aula: ${selectedLesson.title}`,
      {
        courseId: course.id,
        lessonId: (selectedLesson as Lesson).id,
        lessonType: selectedLesson.type,
        bannerLink: bannerLink,
      },
    );

    window.open(bannerLink, '_blank');
  };

  return (
    <CourseContentContainer>
      <PlayerContainer>
        {contentReference ? (
          <PlayerSambaVideos
            contentReference={contentReference}
            controlsToEnable={getControlsToEnable()}
            getEventListeners={getEventListeners}
            resume={(selectedLesson as Lesson).content_view || 0}
          />
        ) : (
          <PlayerIconContainer>
            <PlayerIcon>
              <AiOutlinePlayCircle color="#FFF" size={200} />
            </PlayerIcon>
          </PlayerIconContainer>
        )}
      </PlayerContainer>
      {course.banner?.image ? (
        course.banner.url ? (
          <button
            type="button"
            className="banner-button"
            onClick={() => goToBannerLink(course.banner!.url)}
          >
            <CourseBanner src={course.banner.image} />
          </button>
        ) : (
          <CourseBanner src={course.banner.image} />
        )
      ) : (
        <></>
      )}
      <CourseOrLessonTitle>
        {selectedLesson.title || course.title}
      </CourseOrLessonTitle>
      {!isMobile ? (
        <>
          <CourseTabsAndRating>
            <CourseTabsHeaders>
              {courseTabHeadersToShow && courseTabHeadersToShow.length ? (
                courseTabHeadersToShow.map(header => (
                  <CourseTabHeader
                    key={header.id}
                    header={header}
                    selected={selectedTab === header.id}
                  />
                ))
              ) : (
                <></>
              )}
            </CourseTabsHeaders>
          </CourseTabsAndRating>
          <CourseTabsContent>{tabToBeRendered}</CourseTabsContent>
        </>
      ) : (
        <CourseTabsMobile
          tabHeader={courseTabHeaders}
          contentsWithTabId={[
            { id: 'overview', content: <LessonOverview /> },
            { id: 'extra-material', content: <LessonExtraMaterial /> },
            { id: 'annotations', content: <LessonAnnotations /> },
          ]}
        />
      )}

      {isMobile && !course.alreadyStarted && (
        <StartCourseContainer>
          <StartCourseButton onClick={startCourse}>
            <span>Iniciar Curso</span>
            <FiPlay size={16} />
          </StartCourseButton>
        </StartCourseContainer>
      )}
    </CourseContentContainer>
  );
};

export default CourseContent;
