import React, { useState, useEffect } from 'react';
import ContentCardSkeleton from '../../components/ContentCardSkeleton';
import ContentCard from '../../components/ContentCard';
import {
  VideoLibraryPageContainer,
  VideoLibraryPageTitle,
  VideoLibraryFooterWrapper,
  VideoLibraryPageCard,
} from './style';
import LessonFromApi from '../../models/from-api/lesson';
import { getLibraryContent as getContentsService } from '../../services/content';
import { useMemo } from 'react';
import usePostHog from '../../hooks/usePosthog';
import { CourseTabsHeaders, CourseTabsContent, CourseTabHeaderGroupContainer } from '../Course/Components/CourseContent/styles';

const initialNumberOfContents = 12;

const VideoLibrary: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>();
  const [contents, setContents] = useState<Array<LessonFromApi>>([]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState<boolean>(false);
  const [numberOfContentsToShow, setNumberOfContentsToShow] = useState(
    () => {
      const storedPage = localStorage.getItem('numberOfContentsToShow');    
      return storedPage ? parseInt(storedPage, 10) : initialNumberOfContents;}
  );

  const handleBeforeUnload = (e:any) => {
    localStorage.setItem('numberOfContentsToShow', '12');
    e.returnValue = '';
  };

  useEffect(()=>{
    window.addEventListener('beforeunload', handleBeforeUnload);
    const windowHeight = window.innerHeight
    let yPosition = Number(localStorage.getItem('yPositionContent'));
    if(localStorage.getItem('yPositionContent') != null){
      window.scrollTo({top: yPosition - windowHeight/2, behavior: 'smooth'})
    }
  })

  const posthog = usePostHog();

  const getContents = async () => {
    setShouldShowSkeleton(true);
    const groupId = selectedTab;
    let localContents = await getContentsService({ is_active: true, group: groupId});
    setContents(localContents);
    setShouldShowSkeleton(false);
  };

  interface CourseTabGroupHeader {
    id: number;
    name: string;
  }


  const courseTabGroupHeaders = [
    { id: undefined, name: 'Todos', shouldShow: true },
    {
      id: 1,
      name: 'Lives',
    },
    {
      id: 2,
      name: 'Treinamentos',
    },
    {
      id: 3,
      name: 'Pílulas de conhecimento',
    },
  ] as CourseTabGroupHeader[];

  interface CourseTabHeaderProps {
    header: CourseTabGroupHeader;
    selected: boolean;
  }

  const handleLoadMoreVideos = () => {    
    setNumberOfContentsToShow(numberOfContentsToShow + initialNumberOfContents);
    localStorage.setItem('numberOfContentsToShow', (initialNumberOfContents + numberOfContentsToShow).toString());
    localStorage.removeItem('yPositionContent');    
  };

  const changeTab = (headerId: number) => {
    setSelectedTab(headerId);
  };

  const CourseTabHeader: React.FC<CourseTabHeaderProps> = ({
    header,
    selected,
  }) => {
    return (
      <CourseTabHeaderGroupContainer
        className={selected ? 'selected' : ''}
        onClick={() => changeTab(header.id)}
      >
        {header.name}
      </CourseTabHeaderGroupContainer>
    );
  };

  const contentsToShow = useMemo(() => {
    return [...(contents || [])].splice(0, numberOfContentsToShow);
  }, [contents, numberOfContentsToShow]);

  useEffect(() => {
    posthog?.capture('Pagina Videoteca');
  }, [posthog]);

  useEffect(() => {
    getContents();
  }, [selectedTab]);

  return (
    <VideoLibraryPageContainer>
      <div
        className="content"
        style={{
          background: 'white',
          borderRadius: '5px',
          paddingTop: '5px',
          paddingBottom: '15px',
          marginTop: '16px',
          marginBottom: '16px',
        }}
      >
        <VideoLibraryPageTitle>Assista agora</VideoLibraryPageTitle>
            <CourseTabsHeaders>
              {courseTabGroupHeaders && courseTabGroupHeaders.length ? (
                courseTabGroupHeaders.map(header => (
                  <CourseTabHeader
                    key={header.id}
                    header={header}
                    selected={selectedTab === header.id}
                  />
                ))
              ) : (
                <></>
              )}
            </CourseTabsHeaders>
          <CourseTabsContent>
            <VideoLibraryPageCard>
          {contentsToShow && contentsToShow.length ? (
            contentsToShow.map(content => (
              <React.Fragment key={content.content_id}>
                <ContentCard
                  content={content}
                />
              </React.Fragment>
            ))
          ) : shouldShowSkeleton ? (
            Array(6)
              .fill('')
              .map((_, index) => (
                <ContentCardSkeleton key={index} width={240} height={230} />
              ))
          ) : (
            <h3>Não foram encontrados vídeos disponíveis</h3>
          )}
          </VideoLibraryPageCard>
          </CourseTabsContent>
        <VideoLibraryFooterWrapper>
          {numberOfContentsToShow < contents.length && (
            <h4 onClick={handleLoadMoreVideos}>Carregar Mais</h4>
          )}
        </VideoLibraryFooterWrapper>
      </div>
    </VideoLibraryPageContainer>
  );
};

export default VideoLibrary;
